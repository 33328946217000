import { InjectionToken } from '@angular/core';
import { IBorrowerSimulatorFacade, BorrowerSimulatorFeatureConfiguration } from '../interface/mortgage-simulator-feature.interface';
import { getCustomer, Configuration } from '@oper-client/shared/configuration';
import { CustomersEnum } from '../constants/customers.enum';
import defaultBorrowerSimulatorConfiguration from '../config/borrower-simulatior/default-borrower-simulator.configuration';
import medirectBorrowerSimulatorConfiguration from '../config/borrower-simulatior/medirect-borrower-simulator.configuration';

export const BORROWER_SIMULATOR_FEATURE_CONFIGURATION = new InjectionToken<BorrowerSimulatorFeatureConfiguration>(
	'BorrowerSimulatorFeatureConfiguration'
);

export const BORROWER_SIMULATOR_FACADE_SERVICE = new InjectionToken<IBorrowerSimulatorFacade>('BorrowerSimulatorFacadeService');

export function borrowerSimulatorConfigurationFactory(
	customerConfig: Configuration,
	featureKey: string
): BorrowerSimulatorFeatureConfiguration {
	if (!customerConfig || !customerConfig?.applicationInsights) {
		throw new Error('Customer configuration was not provided');
	}

	const featureConfiguration = customerConfig.featureConfigurations[featureKey] as BorrowerSimulatorFeatureConfiguration;
	if (featureConfiguration) {
		return featureConfiguration;
	} else {
		const customer = getCustomer(customerConfig.applicationInsights) ?? CustomersEnum.BELGIAN;
		console.warn(
			`featureConfigurations.${featureKey} was not found in customer.json! Default '${customer}' simulator configuration will be used instead.`
		);

		switch (customer) {
			case CustomersEnum.MEDIRECT:
				return medirectBorrowerSimulatorConfiguration;
			default:
				return defaultBorrowerSimulatorConfiguration;
		}
	}
}
