import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'iban',
			label: 'ç.question.iban.label',
			value: formData?.iban,
			type: 'text',
			required: false,
			validators: [ValidatorService.getIbanValidator()],
			class: 'span6',
		}),
		new InputField({
			key: 'bic',
			label: 'ç.question.bic.label',
			value: formData?.bic,
			type: 'text',
			required: false,
			validators: [ValidatorService.getBicValidator()],
			class: 'span6',
		}),
		new InputSelect({
			key: 'livingStatus.id',
			label: 'ç.question.livingStatus.label',
			value: formData?.livingStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LIVING_STATUS] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'externalReference',
			label: 'ç.question.externalReference.label',
			value: formData?.externalReference,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		})
	];
}
