import { Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Step, LoanRequest, Resource } from '@oper-client/shared/data-model';
import * as Actions from './borrower-simulator-application-flow.actions';
import * as Selectors from './borrower-simulator-application-flow.selectors';
import { IBorrowerSimulatorFacade, BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class BorrowerSimulatorApplicationFlowFacade implements IBorrowerSimulatorFacade {
	constructor(private store: Store<any>) {}

	readonly loadSimulationReportActionState$ = this.store.pipe(select(Selectors.getLoadSimulationReportActionState));
	readonly configuration$ = this.store.pipe(select(Selectors.getConfiguration));
	readonly dataPoints$ = this.store.pipe(select(Selectors.getDataPoints));
	readonly data$ = this.store.pipe(select(Selectors.getData));
	readonly activeStep$ = this.store.pipe(select(Selectors.getActiveStep));
	readonly configuredSteps$ = this.store.pipe(select(Selectors.getSteps));
	readonly selectedPurpose$ = this.store.pipe(select(Selectors.getSelectedPurpose));

	setActiveStep(step: Step): void {
		this.store.dispatch(Actions.setActiveStep({ step }));
	}

	setSelectedPurpose(purpose: Resource): void {
		this.store.dispatch(Actions.setSelectedPurpose({ purpose }));
	}

	updateActiveStep(changes: Partial<Step>) {
		this.store.dispatch(Actions.updateActiveStep({ changes }));
	}

	getActiveStep(): Signal<Step | null> {
		return toSignal(this.activeStep$);
	}

	getSteps(): Signal<Step[]> {
		return toSignal(this.configuredSteps$);
	}

	getSelectedPurpose(): Signal<Resource> {
		return toSignal(this.selectedPurpose$);
	}

	setConfiguration(configuration: BorrowerSimulatorFeatureConfiguration): void {
		this.store.dispatch(Actions.setConfiguration({ configuration }));
	}

	updateConfiguration(changes: Partial<BorrowerSimulatorFeatureConfiguration>) {
		this.store.dispatch(Actions.updateConfiguration({ changes }));
	}

	nextStep(): void {
		this.store.dispatch(Actions.nextStep());
	}

	previousStep(): void {
		this.store.dispatch(Actions.prevStep());
	}

	setData(data: Partial<LoanRequest>): void {
		this.store.dispatch(Actions.setData({ data }));
	}

	setDataForStep<T>(step: string, data: Partial<T>): void {
		this.store.dispatch(Actions.setDataForStep({ step, data }));
	}

	reset(): void {
		this.store.dispatch(Actions.reset());
	}

	clearData(): void {
		this.store.dispatch(Actions.clearData());
	}
}
