import { InputField, InputSelect, PercentageInputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { orderBy } from '@oper-client/shared/util-array';
import { LoanPurposeEnum, PartialNormalizedResource, Resource, ResourceType } from '@oper-client/shared/data-model';
import { distinctUntilChanged, map, Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { showOnlyWhenAllConditionsAreMet, hideWhenFieldIsFalse } from '../utils/dynamic-form.utils';
import { FormConfiguration } from '../models/dynamic-form.model';

// TODO: export this to a separate file and rename it to something more meaningful
interface ProjectDetailsFormData {
	realtyType: Resource;
	region: Resource;
	usageType: Resource;
	saleType: Resource;
	epcBeforeRenovations: number;
	epcAfterRenovations: number;
	isMarkedForRenovation: boolean;
	renovationCosts: number;
	vatRate: Resource;
	priceBuilding: number;
	architectFees: number;
	isBuyingLand: boolean;
	landPurchaseType: Resource;
	priceOfLand: number;
	hasExistingLoanOnLand: boolean;
	hasExistingLoanOnProperty: boolean;
	outstandingLoanAmount: number;
	priceOfLandUnderLoan: number;
	isRefinancingExistingLoan: boolean;
	interestRateOnLoan: number;
	duration: number;
	totalLoanAmountAtBeginning: number;
	buyOutAmount: number;
	realtyPrice: number;
	venalValueBefore: number;
	loanPurpose: Resource;
}

export default function (formData?: Partial<ProjectDetailsFormData>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const loanPurpose = (formData?.loanPurpose?.definition as string) ?? 'default';

	switch (loanPurpose) {
		case LoanPurposeEnum.PURCHASE:
			return getFormConfigurationForProjectPurposePurchase(formData, resources);
		case LoanPurposeEnum.NEW_BUILD:
			return getFormConfigurationForProjectPurposeNewBuild(formData, resources);
		case LoanPurposeEnum.RENOVATION:
			return getFormConfigurationForProjectPurposeRenovation(formData, resources);
		case LoanPurposeEnum.REFINANCE:
			return getFormConfigurationForProjectPurposeRefinance(formData, resources);
		case LoanPurposeEnum.BUY_OUT:
			return getFormConfigurationForProjectPurposeBuyOut(formData, resources);
		default:
			return [];
	}
}

function getFormConfigurationForProjectPurposePurchase(
	formData?: Partial<ProjectDetailsFormData>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.propertyPrice.label',
			value: formData?.realtyPrice || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputSelect({
			key: 'usageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.usageType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputSelect({
			key: 'saleType.id',
			label: 'ç.question.typeOfSale.label',
			value: formData?.saleType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.PURCHASE_SALE_TYPE],
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'isMarkedForRenovation',
			label: 'ç.question.isMarkedForRenovation.label',
			value: formData?.isMarkedForRenovation || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'renovationCosts',
			label: 'ç.question.renovationCosts.label',
			value: formData?.renovationCosts,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForRenovation'),
		}),
		new InputSelect({
			key: 'vatRate.id',
			label: 'ç.question.vatRate.label',
			value: formData?.vatRate?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.RENOVATION_VAT_RATE],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForRenovation'),
		}),
	];
}

function getFormConfigurationForProjectPurposeNewBuild(
	formData?: Partial<ProjectDetailsFormData>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.usageType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'priceBuilding',
			label: 'ç.question.constructionCostsExcludingVAT.label',
			value: formData?.priceBuilding || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'architectFees',
			label: 'ç.question.architectFees.label',
			value: formData?.architectFees || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.newBuildEpcScore.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'isBuyingLand',
			label: 'ç.question.isBuyingLand.label',
			value: formData?.isBuyingLand || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const key = 'isBuyingLand';
				const conditionFieldKey = 'hasExistingLoanOnLand';
				const isConditionFieldChecked = !!formGroup.value[conditionFieldKey];
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === key);
				const formControl = <FormControl>formGroup.controls[key];
				!isConditionFieldChecked ? formControl.enable() : formControl.disable();

				return formGroup.controls[conditionFieldKey].valueChanges.pipe(
					distinctUntilChanged(),
					map((isChecked) => {
						!isChecked ? formControl.enable() : formControl.disable();
						return inputField;
					})
				);
			},
		}),
		new InputSelect({
			key: 'landPurchaseType.id',
			label: 'ç.question.typeOfPurchase.label',
			value: formData?.landPurchaseType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.LAND_PURCHASE_TYPE],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isBuyingLand'),
		}),
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceOfTheLand.label',
			value: formData?.priceOfLand || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isBuyingLand'),
		}),
		new InputField({
			key: 'hasExistingLoanOnLand',
			label: 'ç.question.hasExistingLoanOnLand.label',
			value: formData?.hasExistingLoanOnLand || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const key = 'hasExistingLoanOnLand';
				const conditionFieldKey = 'isBuyingLand';
				const isConditionFieldChecked = !!formGroup.value[conditionFieldKey];
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === key);
				const formControl = <FormControl>formGroup.controls[key];
				!isConditionFieldChecked ? formControl.enable() : formControl.disable();

				return formGroup.controls[conditionFieldKey].valueChanges.pipe(
					distinctUntilChanged(),
					map((isChecked) => {
						!isChecked ? formControl.enable() : formControl.disable();
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'outstandingLoanAmount',
			label: 'ç.question.outstandingLoanAmount.label',
			value: formData?.outstandingLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnLand'),
		}),
		new InputField({
			key: 'priceOfLandUnderLoan',
			label: 'ç.question.priceOfLandUnderLoan.label',
			value: formData?.priceOfLandUnderLoan || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnLand'),
		}),
		new InputField({
			key: 'isRefinancingExistingLoan',
			label: 'ç.question.isRefinancingExistingLoan.label',
			value: formData?.isRefinancingExistingLoan || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnLand'),
		}),
		new PercentageInputField({
			key: 'interestRateOnLoan',
			label: 'ç.question.interestRateOnLoan.label',
			value: formData?.interestRateOnLoan || null,
			required: true,
			validators: [Validators.min(0), Validators.max(100)],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) =>
				showOnlyWhenAllConditionsAreMet(formGroup, destroy$, { hasExistingLoanOnLand: true, isRefinancingExistingLoan: true }),
		}),
	];
}

function getFormConfigurationForProjectPurposeRenovation(
	formData?: Partial<ProjectDetailsFormData>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.usageType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: formData?.venalValueBefore,
			type: 'number',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'renovationCosts',
			label: 'ç.question.renovationCosts.label',
			value: formData?.renovationCosts,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputSelect({
			key: 'vatRate.id',
			label: 'ç.question.vatRate.label',
			value: formData?.vatRate?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.RENOVATION_VAT_RATE],
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScoreBeforeRenovation.label',
			value: formData?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'hasExistingLoanOnProperty',
			label: 'ç.question.hasExistingLoanOnProperty.label',
			value: formData?.hasExistingLoanOnProperty || false,
			type: 'checkbox',
			required: false,
		}),
		new InputField({
			key: 'outstandingLoanAmount',
			label: 'ç.question.outstandingLoanAmount.label',
			value: formData?.outstandingLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) =>
				hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnProperty'),
		}),
	];
}

function getFormConfigurationForProjectPurposeRefinance(
	formData?: Partial<ProjectDetailsFormData>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.usageType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),

		new InputField({
			key: 'totalLoanAmountAtBeginning',
			label: 'ç.question.totalLoanAmountAtBeginning.label',
			value: formData?.totalLoanAmountAtBeginning || null,
			type: 'number',
			currency: true,
			required: true,
		}),
		new PercentageInputField({
			key: 'interestRateOnLoan',
			label: 'ç.question.interestRateOnLoan.label',
			value: formData?.interestRateOnLoan || null,
			required: true,
			validators: [Validators.min(0), Validators.max(100)],
		}),
		new InputField({
			key: 'duration',
			label: 'ç.question.duration.label',
			value: formData?.duration,
			required: true,
			type: 'number',
			suffix: 'ç.misc.duration.month.plural',
		}),
	];
}

function getFormConfigurationForProjectPurposeBuyOut(
	formData?: Partial<ProjectDetailsFormData>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.usageType?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'buyOutAmount',
			label: 'ç.question.amountIntendedToBuyOut.label',
			value: formData?.buyOutAmount || null,
			type: 'number',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.originalPropertyPrice.label',
			value: formData?.realtyPrice,
			type: 'number',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScoreBeforeRenovation.label',
			value: formData?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'isMarkedForRenovation',
			label: 'ç.question.isMarkedForRenovation.label',
			value: formData?.isMarkedForRenovation || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'renovationCosts',
			label: 'ç.question.renovationCosts.label',
			value: formData?.renovationCosts,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForRenovation'),
		}),
		new InputSelect({
			key: 'vatRate.id',
			label: 'ç.question.vatRate.label',
			value: formData?.vatRate?.id,
			required: true,
			appendTo: null,
			options: resources?.[ResourceType.RENOVATION_VAT_RATE],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForRenovation'),
		}),
	];
}
