import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'title.id',
			label: 'ç.question.title.label',
			value: formData?.title?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CLIENT_TITLE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: formData?.language?.id || '',
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			class: 'span12',
		}),
	];
}
