import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import {
	ConfigurationPortalBaseProduct,
	ConfigurationPortalLoanType,
	interestCalculationMethodMap,
	rateCalculationMethodMap,
} from '@oper-client/configuration-portal/common';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { Validators } from '@angular/forms';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';

export default function (
	formData?: ConfigurationPortalBaseProduct,
	resources?: PartialNormalizedResource,
	loanTypes?: ConfigurationPortalLoanType[]
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'loanType.configurationId',
			label: 'ç.question.amortizationScheme.label',
			value: formData?.loanType.configurationId,
			options: orderBy(
				loanTypes?.map((loanType) => ({
					id: loanType.configurationId,
					key: `ç.resource.loan-type.${loanType.summary}`,
					order: loanType.order,
				})),
				'order'
			),
			class: 'span12',
		}),
		new InputSelect({
			key: 'rateCalculationMethod',
			label: 'ç.configurationPortal.product.rateCalculationMethod',
			value: formData?.rateCalculationMethod,
			options: Array.from(rateCalculationMethodMap.entries()).map(([key, value]) => ({
				id: value,
				key: `ç.configurationPortal.product.rateCalculationMethod.${key}`,
			})),
			class: 'span12',
		}),
		new InputSelect({
			key: 'interestCalculationMethod',
			label: 'ç.configurationPortal.product.interestCalculationMethod',
			value: formData?.interestCalculationMethod,
			options: Array.from(interestCalculationMethodMap.entries()).map(([key, value]) => ({
				id: value,
				key: `ç.configurationPortal.product.interestCalculationMethod.${key}`,
			})),
			class: 'span12',
		}),
		new InputField({
			key: 'daysInYear',
			label: 'ç.configurationPortal.product.daysInYear',
			value: formData?.daysInYear,
			type: 'number',
			class: 'span12',
			placeholder: '365',
			additionalLabel: 'ç.configurationPortal.product.daysInYear.helpText',
			validators: [Validators.min(300), Validators.max(400)],
		}),
		new InputField({
			key: 'firstPaymentDay',
			label: 'ç.configurationPortal.product.firstPaymentDay',
			value: formData?.firstPaymentDay,
			type: 'number',
			class: 'span12',
			required: false,
			placeholder: '30',
			additionalLabel: 'ç.configurationPortal.product.firstPaymentDay.helpText',
		}),
		new InputField({
			key: 'gracePeriod',
			label: 'ç.configurationPortal.product.gracePeriod',
			value: formData?.gracePeriod,
			type: 'number',
			class: 'span12',
			additionalLabel: 'ç.configurationPortal.product.gracePeriod.helpText',
			validators: [Validators.pattern(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS)],
		}),
	];
}
