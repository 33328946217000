import {
	ActionState,
	initialActionState,
	Step,
	StepDataPoint,
	findStepByName,
	generateDataObjectFromStepDataPoints,
	Resource,
} from '@oper-client/shared/data-model';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';
import * as ApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { on } from '@ngrx/store';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';

export const BORROWER_SIMULATOR_APPLICATION_FLOW_KEY = 'borrowerSimulatorApplicationFlow';
export const BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP = 'projectPurpose';

export type BorrowerSimulatorApplicationFlowActionTypes = 'loadSimulationReport';
export type BorrowerSimulatorApplicationFlowActionsState = Record<BorrowerSimulatorApplicationFlowActionTypes, ActionState>;

export interface BorrowerSimulatorApplicationFlowState {
	configuration: BorrowerSimulatorFeatureConfiguration | null;
	activeStep: Step | null;
	dataPoints: StepDataPoint | null;
	data: any;
	selectedPurpose: Resource | null;
	actions: BorrowerSimulatorApplicationFlowActionsState;
}

export const initialState: BorrowerSimulatorApplicationFlowState = {
	configuration: null,
	dataPoints: null,
	activeStep: null,
	data: null,
	selectedPurpose: null,
	actions: { loadSimulationReport: initialActionState },
};

export const reducer = createRehydrateReducer(
	BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
	initialState,
	on(ApplicationFlowActions.setConfiguration, (state, { configuration }) => ({
		...state,
		configuration,
	})),
	on(ApplicationFlowActions.updateConfiguration, (state, { changes }) => ({
		...state,
		configuration: { ...state.configuration, ...changes },
	})),
	on(ApplicationFlowActions.setActiveStep, (state, { step }) => ({
		...state,
		activeStep: step,
	})),
	on(ApplicationFlowActions.updateActiveStep, (state, { changes }) => ({
		...state,
		activeStep: { ...state.activeStep, ...changes },
	})),
	on(ApplicationFlowActions.nextStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.next),
	})),
	on(ApplicationFlowActions.prevStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.back),
	})),

	on(ApplicationFlowActions.setData, (state, { data }) => ({
		...state,
		data,
	})),
	on(ApplicationFlowActions.setDataForStep, (state, { step, data }) => {
		const dataPoints = { ...state.dataPoints, [step]: data };
		const updatedData = { ...initialState.data, ...generateDataObjectFromStepDataPoints<any>(dataPoints) };
		return {
			...state,
			dataPoints,
			data: updatedData,
		};
	}),
	on(ApplicationFlowActions.setStepFormConfiguration, (state, { configuration }) => ({
		...state,
		stepFormConfiguration: configuration,
	})),
	on(ApplicationFlowActions.setSelectedPurpose, (state, { purpose }) => ({
		...state,
		selectedPurpose: purpose,
	})),
	on(ApplicationFlowActions.reset, () => ({
		...initialState,
	})),
	on(ApplicationFlowActions.clearData, (state) => ({
		...state,
		data: null,
		dataPoints: null,
		selectedPurpose: null,
	}))
);
