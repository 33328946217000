import { createAction, props } from '@ngrx/store';
import { LoanRequest, Resource, Step } from '@oper-client/shared/data-model';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { FormConfiguration } from '@oper-client/shared/util-dynamic-form';

export const setActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Set Active Step', props<{ step: Step }>());
export const updateActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Update Active Step', props<{ changes: Partial<Step> }>());
export const setConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Configuration',
	props<{ configuration: BorrowerSimulatorFeatureConfiguration }>()
);
export const updateConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Configuration',
	props<{ changes: Partial<BorrowerSimulatorFeatureConfiguration> }>()
);
export const nextStep = createAction('[BorrowerSimulatorApplicationFlow] Next Step');
export const prevStep = createAction('[BorrowerSimulatorApplicationFlow] Previous Step');
export const reset = createAction('[BorrowerSimulatorApplicationFlow] Reset');
export const clearData = createAction('[BorrowerSimulatorApplicationFlow] Clear Data');
export const setData = createAction('[BorrowerSimulatorApplicationFlow] Set Data', props<{ data: Partial<LoanRequest> }>());
export const setDataForStep = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Data For Step',
	props<{ step: string; data: Partial<any> }>()
);
export const setStepFormConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Step Form Configuration',
	props<{ configuration: FormConfiguration }>()
);

export const setSelectedPurpose = createAction('[BorrowerSimulatorApplicationFlow] Set Selected Purpose', props<{ purpose: Resource }>());
