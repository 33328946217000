import { Injectable } from '@angular/core';
import * as BorrowerSimulatorApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP } from './borrower-simulator-application-flow.reducer';

@Injectable()
export class BorrowerSimulatorApplicationFlowEffects {
	readonly setSelectedPurpose$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.setSelectedPurpose),
			map(({ purpose }) =>
				BorrowerSimulatorApplicationFlowActions.setDataForStep({
					step: BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP,
					data: { loanPurpose: purpose },
				})
			)
		)
	);

	constructor(private actions$: Actions) {}
}
