import { Email, PartialNormalizedResource, PhoneNumber, ResourceType } from '@oper-client/shared/data-model';
import { InputField, InputPhone } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (
	data: {
		email: Email;
		mainPhoneNumber: PhoneNumber;
		secondPhoneNumber: PhoneNumber;
	},
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputField({
			key: 'emails[0].value',
			label: 'ç.question.email.label',
			value: data?.email.value || '',
			type: 'text',
			required: false,
			validators: [ValidatorService.trimmedEmailValidator],
			class: 'span12',
		}),
		new InputPhone({
			key: 'phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: data.mainPhoneNumber || '',
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: false,
			class: 'span12',
			allowedTypes: ['MOBILE'],
		}),
	];
}
